<template>
<div class="index">
  <navbar />
  <app-main />
  <foot/>
</div>
</template>

<script>
// import { AppMain, Navbar } from './components'
import Navbar from './components/Navbar'
import AppMain from './components/AppMain'
import Foot from './components/Foot'
export default {
  name: "index",
components:{
  AppMain,
  Navbar,Foot
}
}
</script>

<style scoped>

</style>
