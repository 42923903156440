import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import method from "@/utils/method";
import ElementPlus from 'element-plus'
import Vuex from 'vuex'
import store from "@/store";

let app = createApp(App);
app.config.globalProperties.$m = method
app.use(router)
    .use(ElementPlus).use(Vuex).use(store)
    .mount('#app')

import '@/assets/css/public.scss'
import "@/assets/font/font.css";
import 'element-plus/dist/index.css'

let jump = function (item) {
    switch (item.jump_type) {
        case 1: //分类
            this.$router.push(`goods/goodslist/${item.category_id}/${item.name}`);
            break;
        case 2: //宣传页
            // this.$m.Tips("/pages/richtext/richtext?content=" + item.propagate)
            break;
        case 3: //外部链接
            window.open(encodeURIComponent(item.jump_link))
            break;
        case 4: //商品
            this.$router.push({
                path: `/goods/goodsdetail/${item.id}`
            })
            break;
        case 5: //全部商品
            break;
        case 6: //二手市场
            this.$router.push({
                path: `goods/goodslist/no/${item.name}`,
                query:{
                    old:1,
                }
            })
            // this.$m.Tips("/pages/index/ershou/ershou")
            break;
    }
}
let priceFomat = (value)=>{
    let price = parseInt(value);
    if(price<1000){
        return price
    }else if(price < 10000){
        return price/1000 + "K"
    }else{
        return price/10000 + "W"
    }
}
app.config.globalProperties.$jump = jump;
app.config.globalProperties.$priceFomat = priceFomat;
import config from '@/config/config.js'

app.config.globalProperties.$apiBaseUrl = config.https;

// 引入全局组件
import { Plus } from '@element-plus/icons-vue'
// 注册全局组件
app.component("Plus",Plus);
