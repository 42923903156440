<template>
  <div style="position: sticky;top: 0;z-index: 999;" class="bgf w100_">
    <div class="navbar bgf flex align-center just-center bsiz pcc">
      <div class="flex just-between w100_">
        <div class="flex">
         <div class="h100_ flex align-center mr60">
           <img :src="$m.img('public/logotwo.png')"  style="width: 180px;height: 50px;" alt=""/>
         </div>
          <el-menu
              :default-active="$route.matched[0].path"
              class="el-menu-demo" style="border-bottom: 0"
              active-text-color="#DE3534"
              mode="horizontal"
              :ellipsis="false"
              @select="handleSelect"
              :router="true"
          >
            <el-menu-item v-for="(item,index) in navList" :key="index" :index="item.path">{{ item.title }}
            </el-menu-item>
          </el-menu>
        </div>
        <div class="h80 flex align-center just-center" v-if="$store.state.user.token">

          <img :src="$store.state.user.user.head_pic || $m.img('public/head_img.jpg')" class="h48 w48 br50_ cp" @click="$router.push('/my')" alt="">
        </div>
        <div class="flex" v-else>
          <div class="w120 h80 flex just-center align-center tc_33 fs16 cp" @click="goLogin(1)">
            登陆
          </div>
          <div class="w120 h80 flex just-center align-center bg_Theme tc_f fs16 cp" @click="goLogin(2)">
            注册
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pcc fs14 tc_99 lh20 bsiz pt20 pb20 pl20 pr20" style="background-color: #f9f9f9;" v-if="$route.path!='/index'" >
    <el-breadcrumb :separator-icon="ArrowRight">
      <el-breadcrumb-item :to="{ path: item.redirect || item.path }" v-for="(item,index) in $route.matched"
                          :key="index">
        {{ index == ($route.matched.length - 1) ? ($route.params.title || item.meta.title) : item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import {ArrowRight} from '@element-plus/icons-vue'

export default {
  data() {
    return {
      navList: [
        {
          title: "首页",
          path: "/",
        }, {
          title: "人才",
          path: "/work",
        }, {
          title: "配比",
          path: "/ratio",
        }, {
          title: "复配",
          path: "/formulation",
        }, {
          title: "个人中心",
          path: "/my",
        }
      ],

      activeIndex: 0,
    }
  },
  mounted() {
    // this.getUserInfo();
    console.log("AAA",this.$store.state.user)
  },
  methods: {
    getUserInfo(){
      this.$store.dispatch("getInfo", this.$store.state);
    },
    goLogin(type) {
      this.$router.push({
        path: "/login",
        query: {type: type}
      })
    }
  }
}
</script>

<style scoped>
.navbar {

}
</style>
