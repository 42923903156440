<template>
<div class="w100_ fs14 tc_99 lh20 ta_c pt20 pb20">
  Copyright © 2022 BOSS直聘京ICP备14013441号-5京ICP证150923号京网文[2020]0399-066 号
</div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>