<template>
  <router-view />
</template>

<script>
import {useRoute, useRouter} from "vue-router"
export default {
  name: 'App',
  components: {
  },
  setup(){
    const route = useRoute();
    const router = useRouter();
    return {
      route,
      router
    }
  }

}
</script>

<style>

</style>
