//防抖
let _debounceTimeout = null;

import {ElMessage} from "element-plus";
export default {

	/**
	 * 返回上一页
	 */
	goBack: function() {
		this.$router.back();
	},
	/**
	 * 拼接图片全路径
	 * @param {Object} url 图片名
	 */
	img: function(url) {
		return  require(`@/assets/img/${url}`);
	},
	/**
	 * 富文本图片宽度超出解决
	 * @param {Object} richText 富文本
	 */
	richTextImageFormat(richText) {
		return richText.replace(/<img/gi, '<img style="width:100%;height:auto" ');
	},
	/**
	 * 获取url参数
	 * @param {Object} name
	 */
	getUrlParam(name) {
		return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [,
			""])[1].replace(/\+/g, '%20')) || null
	},
	// 复制操作
	copy(context) {
		// 创建输入框元素
		let oInput = document.createElement('input');
		// 将想要复制的值
		oInput.value = context;
		// 页面底部追加输入框
		document.body.appendChild(oInput);
		// 选中输入框
		oInput.select();
		// 执行浏览器复制命令
		document.execCommand('Copy');
		// 弹出复制成功信息
		ElMessage(
			{
				type: "success",
				message: "复制成功!"
			}
		)
		// 复制后移除输入框
		oInput.remove();
	},
	/**
	 * 去到页面
	 * @param {*} url 页面路径
	 */
	goPage: function(path,query) {
		this.$router.push({path:path,query:query})
	},
	// 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
	underline2Hump: function(s) {
		return s.replace(/_(\w)/g, function(all, letter) {
			return letter.toUpperCase()
		})
	},
	// JSON对象的key值转换为驼峰式
	jsonToHump: function(obj) {
		if (obj instanceof Array) {
			obj.forEach((v, i) => {
				this.jsonToHump(v)
			})
		} else if (obj instanceof Object) {
			Object.keys(obj).forEach((key) => {
				var newKey = this.underline2Hump(key)
				if (newKey !== key) {
					obj[newKey] = obj[key]
					delete obj[key]
				}
				this.jsonToHump(obj[newKey])
			})
		}
	},
	// 字符串的驼峰格式转下划线格式，eg：helloWorld => hello_world
	hump2Underline: function(s) {
		return s.replace(/([A-Z])/g, '_$1').toLowerCase()
	},
	// JSON对象的key值转换为下划线格式
	jsonToUnderline: function(obj) {
		if (obj instanceof Array) {
			obj.forEach((v, i) => {
				this.jsonToUnderline(v)
			})
		} else if (obj instanceof Object) {
			Object.keys(obj).forEach((key) => {
				var newKey = this.hump2Underline(key)
				if (newKey !== key) {
					obj[newKey] = obj[key]
					delete obj[key]
				}
				this.jsonToUnderline(obj[newKey])
			})
		}
	},
	/**
	 * 防抖
	 * @param {Function} 执行函数
	 * @param {Number} delay 延时ms
	 * @param {Boolean} immediate 立即执行
	 */
	debounce: function(fn, delay = 500, immediate = false) {
		if (!_debounceTimeout && immediate) fn()

		clearTimeout(_debounceTimeout)
		_debounceTimeout = setTimeout(() => {
			if (!immediate) fn()
			_debounceTimeout = null
		}, delay);
	},
	/**
	 * 防抖-封装-常用
	 */
	dbe: function(fn, ...params) {
		this.debounce(fn.bind(this, ...params), 500, true)
	},

	/*
	 * 单图上传
	 * @param object opt
	 * @param callable successCallback 成功执行方法 data
	 * @param callable errorCallback 失败执行方法
	 */
	// uploadImageOne: function(opt, successCallback, errorCallback) {
	// 	let that = this;
	// 	if (typeof opt === 'string') {
	// 		let url = opt;
	// 		opt = {};
	// 		opt.url = url;
	// 	}
	// 	let count = opt.count || 1,
	// 		sizeType = opt.sizeType || ['compressed'],
	// 		sourceType = opt.sourceType || ['album', 'camera'],
	// 		is_load = opt.is_load || true,
	// 		uploadUrl = opt.url || '',
	// 		inputName = opt.name || 'field';
	// 	uni.chooseImage({
	// 		count: count, //最多可以选择的图片总数
	// 		sizeType: sizeType, // 可以指定是原图还是压缩图，默认二者都有
	// 		sourceType: sourceType, // 可以指定来源是相册还是相机，默认二者都有
	// 		success: function(res) {
	// 			//启动上传等待中...
	// 			uni.showLoading({
	// 				title: '图片上传中',
	// 			});
	// 			uni.uploadFile({
	// 				url: HTTP_REQUEST_URL + uploadUrl,
	// 				filePath: res.tempFilePaths[0],
	// 				name: inputName,
	// 				formData: {
	// 					'filename': inputName
	// 				},
	// 				header: {
	// 					// #ifdef MP
	// 					"Content-Type": "multipart/form-data",
	// 					// #endif
	// 					// [TOKENNAME]: 'Bearer ' + store.state.app.token
	// 				},
	// 				success: function(res) {
	// 					console.log(res, 'res22222222222222')
	// 					uni.hideLoading();
	// 					if (res.statusCode == 403) {
	// 						that.Tips({
	// 							title: res.data
	// 						});
	// 					} else {
	// 						let data = res.data ? JSON.parse(res.data) : {};
	// 						if (data.code == 0) {
	// 							successCallback && successCallback(data)
	// 						} else {
	// 							errorCallback && errorCallback(data);
	// 							that.Tips({
	// 								title: data.message
	// 							});
	// 						}
	// 					}
	// 				},
	// 				fail: function(res) {
	// 					uni.hideLoading();
	// 					that.Tips({
	// 						title: '上传图片失败'
	// 					});
	// 				}
	// 			})
	// 			// pathToBase64(res.tempFilePaths[0])
	// 			// 	.then(imgBase64 => {
	// 			// 		console.log(imgBase64);
	//
	// 			// 	})
	// 			// 	.catch(error => {
	// 			// 		console.error(error)
	// 			// 	})
	// 		}
	// 	})
	// },
	/**
	 * opt  object | string
	 * to_url object | string
	 * 例:
	 * this.Tips('/pages/test/test'); 跳转不提示
	 * this.Tips({title:'提示'},'/pages/test/test'); 提示并跳转
	 * this.Tips({title:'提示'},{tab:1,url:'/pages/index/index'}); 提示并跳转值table上
	 * tab=1 一定时间后跳转至 table上
	 * tab=2 一定时间后跳转至非 table上
	 * tab=3 一定时间后返回上页面
	 * tab=4 关闭所有页面跳转至非table上
	 * tab=5 关闭当前页面跳转至table上
	 */
	// Tips: function(opt, to_url) {
	// 	if (typeof opt == 'string') {
	// 		to_url = opt;
	// 		opt = {};
	// 	}
	// 	let title = opt.title || '',
	// 		icon = opt.icon || 'none',
	// 		endtime = opt.endtime || 2000,
	// 		success = opt.success;
	// 	if (title) uni.showToast({
	// 		title: title,
	// 		icon: icon,
	// 		duration: endtime,
	// 		success
	// 	})
	// 	if (to_url != undefined) {
	// 		if (typeof to_url == 'object') {
	// 			let tab = to_url.tab || 1,
	// 				url = to_url.url || '';
	// 			switch (tab) {
	// 				case 1:
	// 					//一定时间后跳转至 table
	// 					setTimeout(function() {
	// 						uni.switchTab({
	// 							url: url
	// 						})
	// 					}, endtime);
	// 					break;
	// 				case 2:
	// 					//跳转至非table页面
	// 					setTimeout(function() {
	// 						uni.navigateTo({
	// 							url: url,
	// 						})
	// 					}, endtime);
	// 					break;
	// 				case 3:
	// 					//返回上页面
	// 					setTimeout(function() {
	// 						// #ifndef H5
	// 						uni.navigateBack({
	// 							delta: parseInt(url),
	// 						})
	// 						// #endif
	// 						// #ifdef H5
	// 						history.back();
	// 						// #endif
	// 					}, endtime);
	// 					break;
	// 				case 4:
	// 					//关闭当前所有页面跳转至非table页面
	// 					setTimeout(function() {
	// 						uni.reLaunch({
	// 							url: url,
	// 						})
	// 					}, endtime);
	// 					break;
	// 				case 5:
	// 					//关闭当前页面跳转至非table页面
	// 					setTimeout(function() {
	// 						uni.redirectTo({
	// 							url: url,
	// 						})
	// 					}, endtime);
	// 					break;
	// 			}
	//
	// 		} else if (typeof to_url == 'function') {
	// 			setTimeout(function() {
	// 				to_url && to_url();
	// 			}, endtime);
	// 		} else {
	// 			//没有提示时跳转不延迟
	// 			setTimeout(function() {
	// 				uni.navigateTo({
	// 					url: to_url,
	// 				})
	// 			}, title ? endtime : 0);
	// 		}
	// 	}
	// },
}
