import {createRouter, createWebHashHistory} from "vue-router";

// import HelloWord from '../components/HelloWorld.vue'
/* Layout */
import Layout from '@/layout'
import {getToken} from "@/utils/auth";
import store from "@/store";

export const constantRoutes = [
    {
        path: "/",
        redirect: '/index',
        component: Layout,
        meta: {title: '首页'},
        children: [
            {
                path: 'index',
                component: () => import('@/views/index'),
                name: 'Index',
                meta: {title: '首页'}
            },
            {
                path: 'goods/goodslist/:id/:title',
                component: () => import('@/views/goods/goodslist/index'),
                name: 'goodslist',
                meta: {title: '商品列表'}
            },
            {
                path: 'goods/goodsdetail/:id',
                component: () => import('@/views/goods/goodsdetail/index'),
                name: 'goodsdetail',
                meta: {title: '商品详情'}
            }, {
                path: "/forgot",
                component: () => import('@/views/forgotPassword'),
                meta: {title: '忘记密码'}
            }, {
                path: "/registered",
                component: () => import('@/views/registered'),
                meta: {title: '企业注册'}
            }
        ],

    },
    {
        path: "/work",
        redirect: '/work/index',
        component: Layout,
        meta: {title: '人才', keepAlive: true},
        children: [
            {
                path: "index",
                component: () => import('@/views/work'),
                meta: {title: '列表', keepAlive: true}
            },
            {
                path: "recruitment/:item",
                component: () => import('@/views/work/recruitment'),
                meta: {title: '招聘'}
            }, {
                path: "jobHunting/:item",
                component: () => import('@/views/work/jobHunting'),
                meta: {title: '求职'}
            },
        ]
    },
    {
        path: "/ratio",
        redirect: '/ratio/index',
        component: Layout,
        meta: {title: '配比'},
        children: [
            {
                path: "index",
                component: () => import('@/views/ratio'),
                meta: {title: '列表', keepAlive: true}
            }, {
                path: "code/:id",
                component: () => import('@/views/ratio/code/'),
                meta: {title: '代码列表'},

            },
            {
                path: "code/detail/:id",
                component: () => import('@/views/ratio/code/detail'),
                meta: {title: '代码详情'},
            }, {
                path: "param/:pt",
                component: () => import('@/views/ratio/param'),
                meta: {title: '参数'},
            }
        ]
    },
    {
        path: "/formulation",
        redirect: '/formulation/index',
        component: Layout,
        meta: {title: '复配'},
        children: [
            {
                path: "index",
                component: () => import('@/views/formulation'),
                meta: {title: '列表'}
            },
            {
                path: "detail",
                component: () => import('@/views/formulation/detail'),
                meta: {title: '复配详情'},
            }]
    },
    {
        path: "/my",
        redirect: '/my/index',
        component: Layout,
        meta: {title: '个人中心'},
        children: [
            {
                path: "index",
                component: () => import('@/views/my'),
                meta: {title: '我的'}
            },
            {
                path: "membership",
                component: () => import('@/views/my/membership'),
                meta: {title: '会员模式'}
            },
            {
                path: "feedback",
                component: () => import('@/views/my/feedback'),
                meta: {title: '意见反馈'}
            },
            {
                path: "video",
                component: () => import('@/views/my/video'),
                meta: {title: '学习视频'},
            },
            {
                path: "videoDetail/:detail",
                component: () => import('@/views/my/video/detail'),
                meta: {title: '视频详情'}
            },
            {
                path: "myPublish",
                component: () => import('@/views/my/myPublish'),
                meta: {title: ''}
            }, {
                path: "collect",
                component: () => import('@/views/my/collect'),
                meta: {title: '我的收藏'}
            }, {
                path: "enterprise",
                component: () => import('@/views/my/enterprise'),
                meta: {title: '企业信息'}
            }, {
                path: "information",
                redirect: '/my/information/index',
                // component: () => import('@/views/my/information'),
                meta: {title: '我的消息'},
                children: [
                    {
                        path: "index",
                        component: () => import('@/views/my/information'),
                        meta: {title: '列表'}
                    },
                    {
                        path: "detail/:info",
                        component: () => import('@/views/my/information/detail'),
                        meta: {title: '详情'}
                    },
                ]
            }, {
                path: "airplane",
                redirect: '/my/airplane/index',
                meta: {title: '我的发布'},
                children: [
                    {
                        path: "index",
                        component: () => import('@/views/my/airplane'),
                        meta: {title: '列表'}
                    },
                    {
                        path: 'addGoods',
                        component: () => import('@/views/my/airplane/addGoods'),
                        meta: {title: '发布商品'}
                    },
                    {
                        path: 'addRecruit',
                        component: () => import('@/views/my/airplane/addRecruit'),
                        meta: {title: '发布招聘'}
                    }, {
                        path: 'addJob',
                        component: () => import('@/views/my/airplane/addJob'),
                        meta: {title: '发布求职'}
                    }
                ]
            },
        ]
    },
    {
        path: "/login",
        component: () => import('@/views/login'),
    }
]
const newRouter = () => createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(),
    routes: constantRoutes
});
const router = newRouter();
//路由守卫
router.beforeEach((to, from, next) => {
    let noLoginPaths = ['/registered', '/login', '/forgot']

    if (getToken()) {
        if (!store.state.user.user.id) {
            store.dispatch("getInfo");
        }
    } else {
        if (noLoginPaths.indexOf(to.path) != -1) {
            next()
        } else {
            next('/login')
        }
    }
    next();
});

export function resetRouter() {
    const newRouter = newRouter();
    router.matcher = newRouter.matcher // reset router
}

export default router
