import request from "@/utils/request";

let controller = "appClient";

/**
 * @description 忘记密码❤️
 */
export function forgetPassword(data) {
    return request.post(`${controller}/forgetPassword`, data)
}

/**
 * @description app用户登录
 */
export function login(data) {
    return request.post(`${controller}/login`, data)
}

/**
 * @description 查询关于我们❤️
 */
export function queryAbout(data) {
    return request.post(`${controller}/queryAbout`, data)
}

/**
 * @description 根据用户id查询用户信息❤️
 */
export function queryClientById() {
    return request.post(`${controller}/queryClientById`)
}

/**
 * @description 查询用户是否可以进入配比❤️
 */
export function queryClientCanRatio() {
    return request.post(`${controller}/queryClientCanRatio`)
}

/**
 * @description 查询企业信息❤️
 */
export function queryCompany() {
    return request.post(`${controller}/queryCompany`)
}

/**
 * @description 查询企业成员❤️
 */
export function queryCompanyStaff(id) {
    return request.post(`${controller}/queryCompanyStaff?companyId=${id}`)
}

/**
 * @description app用户注册
 */
export function register(data) {
    return request.post(`${controller}/register`, data)
}

/**
 * @description 修改用户个人信息❤️
 */
export function updateClient(data) {
    return request.post(`${controller}/updateClient`, data)
}

/**
 * @description 修改企业信息❤️
 */
export function updateCompany(data) {
    return request.post(`${controller}/updateCompany`, data)
}

/**
 * @description 修改密码❤️
 */
export function updatePassword(data) {
    return request.post(`${controller}/updatePassword`, data)
}

/**
 * @description 修改企业成员权限❤️
 */
export function updateStaffPower(data) {
    return request.post(`${controller}/updateStaffPower`, data)
}





