import axios from 'axios'
import store from '@/store'
import config from '@/config/config'
import {ElMessage} from "element-plus";
const instance = axios.create({
    baseURL: config.https,
    timeout: 60000
})
const defaultOpt = {login: true}

 let baseRequest =   (options) => {

    const token = store.getters.token
    const headers = options.headers || {}
    if (token) {
        headers['X-Token'] = token
        options.headers = headers
    }
    return new Promise((resolve, reject) => {

        if (!options.data) {
            options.data = {}
        }
        if (options.data.clientId == undefined) {
            options.data.clientId = token;
        }
        if (options.url.indexOf('?') == -1) {
            options.url += "?clientId=" + token;
        } else {
            options.url += "&clientId=" + token;
        }

        instance(options).then(res => {
            const data = res.data || {}
            if (res.status !== 200) {
                return reject({message: '请求失败', res, data})
            }
            if ([410000, 410001, 410002, 40000].indexOf(data.code) !== -1) {
                store.dispatch('user/resetToken').then(() => {
                    location.reload()
                })
            } else if (data.code === 0) {
                return resolve(data, res)
            } else if (data.code === 500) {
                ElMessage(
                    {
                        type: "error",
                        message: data.msg
                    }
                )
                console.log("datadata",data)
                return reject({message: '请求失败', res, data});
            } else {
                return reject({message: data.msg, res, data})
            }
        }).catch(message => reject({message}));
    })
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ['post', 'put', 'patch', 'delete'].reduce((request, method) => {
    /**
     *
     * @param url string 接口地址
     * @param data object get参数
     * @param options object axios 配置项
     * @returns {AxiosPromise}
     */
    request[method] = (url, data = {}, options = {}) => {
        return baseRequest(
            Object.assign({url, data, method}, defaultOpt, options)
        )
    }
    return request
}, {});

['get', 'head'].forEach(method => {
    /**
     *
     * @param url string 接口地址
     * @param params object get参数
     * @param options object axios 配置项
     * @returns {AxiosPromise}
     */
    request[method] = (url, params = {}, options = {}) => {

        return baseRequest(
            Object.assign({url, params, method}, defaultOpt, options)
        )
    }
})

export default request
